import React from 'react';
import PropTypes from 'prop-types';

const TabContainer = ({ children, tab, currentTab }) => (
  <div
    style={{
      height: '100%',
      display: tab !== currentTab && 'none',
    }}
  >
    {children}
  </div>
);

TabContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  tab: PropTypes.string.isRequired,
  currentTab: PropTypes.string.isRequired,
};

export default TabContainer;
