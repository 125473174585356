import React, { useCallback } from 'react';
import { InputNumber, Typography, Form, Alert, Radio } from 'antd';
import SharedDrawer from 'components/SharedDrawer';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  hideAdjustVolumeDrawer,
  pressVolumeUpButton,
  pressVolumeDownButton,
} from 'redux/Inspector/slice';
import { adjustVolumeDrawerVisibleSelector } from 'redux/Inspector/selector';
import { Trans } from 'react-i18next';
import Icon from '@ant-design/icons';
import SvgIcons from 'images/svg';

const { Title, Text } = Typography;

export default function AdjustVolumeDrawer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { getFieldsValue } = form;
  const visible = useSelector(adjustVolumeDrawerVisibleSelector);

  const handleSubmit = useCallback(() => {
    const { adjustType, times } = getFieldsValue();

    if (adjustType === 'up') {
      dispatch(pressVolumeUpButton(times));
    } else if (adjustType === 'down') {
      dispatch(pressVolumeDownButton(times));
    }
  }, [dispatch, getFieldsValue]);

  const handleClose = useCallback(() => {
    dispatch(hideAdjustVolumeDrawer());
  }, [dispatch]);

  const warningDescription = (
    <Trans i18nKey="volumeWarning">
      <Text strong />
    </Trans>
  );

  return (
    <SharedDrawer
      width={480}
      visible={visible}
      placement="left"
      cancelText={t('cancel')}
      okText={t('submit')}
      onCancel={handleClose}
      onSubmit={handleSubmit}
    >
      <Title level={2}>{t('adjustVolume')}</Title>
      <Form form={form}>
        <Form.Item name="adjustType" initialValue="up">
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="up">
              <Icon component={SvgIcons.VolumeUp} /> {t('volumeUp')}
            </Radio.Button>
            <Radio.Button value="down">
              <Icon component={SvgIcons.VolumeDown} /> {t('volumeDown')}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="times" label={t('numberOfPresses')} initialValue="5">
          <InputNumber min={1} max={20} />
        </Form.Item>
      </Form>
      <Alert
        showIcon
        type="warning"
        message={t('notice')}
        description={warningDescription}
      />
    </SharedDrawer>
  );
}
