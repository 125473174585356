import React, { useCallback, useState } from 'react';
import GoToNode from 'containers/ActionFlow/GoToNode';
import ReactFlow, { MiniMap, Controls, Background } from 'react-flow-renderer';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Typography } from 'antd';
import { Trans } from 'react-i18next';
import usePlatform from 'hooks/usePlatform';
import {
  pureElementsDataSelector,
  componentsDataSelector,
} from 'redux/Inspector/selector';
import {
  updateActionData,
  updateComponentData,
  connectActions,
  setZoom,
  setPureElements,
  removeActions,
  updateEdgeConnection,
} from 'redux/Inspector/slice';
import { useElements } from 'utils/action-flow';
import './ActionFlow.less';
import { removeFuncInObject } from 'utils/object';
import isEqual from 'react-fast-compare';
import { nodeTypes, edgeTypes } from './ActionFlowTypes';

const { Text } = Typography;

const snapGrid = [16, 16];
const defaultPosition = [150, 0];

const ActionFlow = () => {
  const [instance, setInstance] = useState();
  const componentsData = useSelector(componentsDataSelector);
  const pureElementsData = useSelector(pureElementsDataSelector);
  const dispatch = useDispatch();

  const { isMac } = usePlatform();

  const onUpdateAction = useCallback(
    (data) => {
      dispatch(updateActionData({ data }));
    },
    [dispatch]
  );

  const onUpdateComponent = useCallback(
    (data) => {
      dispatch(updateComponentData({ data }));
    },
    [dispatch]
  );

  const elements = useElements(pureElementsData, componentsData, {
    onUpdateAction,
    onUpdateComponent,
  });

  const getInternalElements = useCallback(() => {
    if (instance) {
      return removeFuncInObject(instance.toObject().elements);
    }
    return [];
  }, [instance]);

  const handleEdgeUpdate = useCallback(
    (oldEdge, newConnection) => {
      const internalElements = getInternalElements();

      dispatch(
        updateEdgeConnection({ oldEdge, newConnection, internalElements })
      );
    },
    [dispatch, getInternalElements]
  );

  const handleConnect = useCallback(
    (params) => {
      const internalElements = getInternalElements();
      dispatch(connectActions(params, internalElements));
    },
    [dispatch, getInternalElements]
  );

  const handleElementsRemove = useCallback(
    (elementsToRemove) => {
      const internalElements = getInternalElements();
      dispatch(removeActions({ elementsToRemove, internalElements }));
    },
    [dispatch, getInternalElements]
  );

  const handleNodeDragStop = useCallback(() => {
    // TODO: Need to update elements position in store after dragging
    const internalElements = getInternalElements();
    dispatch(setPureElements({ elements: internalElements }));
  }, [dispatch, getInternalElements]);

  const handleMoveEnd = useCallback(
    ({ zoom }) => {
      dispatch(setZoom({ zoom }));
    },
    [dispatch]
  );

  const handleNodeColor = useCallback((n) => {
    if (n.style?.background) return n.style.background;
    if (n.type === 'StartNode') return '#9cf196';
    if (n.type === 'EndNode') return '#ff0072';
    return '#e4e4e4';
  }, []);

  return (
    <ReactFlow
      className="action-flow"
      elements={elements}
      snapToGrid
      snapGrid={snapGrid}
      onLoad={setInstance}
      onEdgeUpdate={handleEdgeUpdate}
      onElementsRemove={handleElementsRemove}
      onConnect={handleConnect}
      onMoveEnd={handleMoveEnd}
      onNodeDragStop={handleNodeDragStop}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      defaultPosition={defaultPosition}
    >
      <MiniMap nodeColor={handleNodeColor} borderRadius={2} />
      <Controls />
      <Alert
        className="manual"
        message={
          <>
            <div style={{ marginBottom: 4 }}>
              <Trans
                i18nKey="multiSelectItems"
                values={{ key: isMac ? 'Cmd' : 'Ctrl' }}
              >
                <Text strong />
                <Text keyboard />
              </Trans>
            </div>
            <div>
              <Trans i18nKey="deleteItems">
                <Text strong />
                <Text keyboard />
              </Trans>
            </div>
          </>
        }
        type="info"
      />
      <Background color="#aaa" gap={16} />
      <GoToNode elements={elements} />
    </ReactFlow>
  );
};

export default React.memo(ActionFlow, isEqual);
