import React, { useCallback } from 'react';
import { Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomInput from 'components/Styled/CustomInput';
import SharedDrawer from 'components/SharedDrawer';
import ReferenceStorage from 'containers/ReferenceStorage';
import { useDispatch, useSelector } from 'react-redux';
import { applyClientMethod, hideAssertDrawer } from 'redux/Inspector/slice';
import {
  selectedElementIdSelector,
  assertDrawerVisibleSelector,
} from 'redux/Inspector/selector';
import { assertCriteria } from 'components/Inspector/criteria';

const { Option } = Select;

function AssertDrawer() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { setFieldsValue, validateFields, resetFields } = form;
  const elementId = useSelector(selectedElementIdSelector);

  const visible = useSelector(assertDrawerVisibleSelector);

  const handleChange = (value, variable) => {
    setFieldsValue({ value, variable });
  };

  const handleClose = useCallback(() => {
    resetFields();

    dispatch(hideAssertDrawer());
  }, [dispatch, resetFields]);

  const handleSubmit = useCallback(async () => {
    try {
      const { assertType, variable, value } = await validateFields();
      await dispatch(
        applyClientMethod({
          methodName: 'text',
          elementId,
          args: [value, variable, assertType],
          skipScreenshotAndSource: true,
          // skipExec: true,
        })
      );

      // TODO: Clear form after submit
      handleClose();
    } catch (error) {
      console.error('AssertDrawer handleSubmit error:', error);
    }
  }, [dispatch, elementId, handleClose, validateFields]);

  return (
    <SharedDrawer
      title={t('assertion')}
      width={480}
      onCancel={handleClose}
      visible={visible}
      placement="left"
      cancelText={t('cancel')}
      okText={t('submit')}
      onSubmit={handleSubmit}
    >
      <Form layout="vertical" form={form}>
        <Form.Item>
          <CustomInput label={t('assertType')}>
            <Form.Item
              noStyle
              name="assertType"
              rules={[
                {
                  required: true,
                  message: t('selectType'),
                },
              ]}
            >
              <Select
                showSearch
                placeholder={t('selectType')}
                className="remove-border-left"
              >
                {assertCriteria.map(({ key, value }) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </CustomInput>
        </Form.Item>
        <Form.Item name="variable">
          <Input addonBefore={t('variableName')} disabled />
        </Form.Item>
        <Form.Item
          name="value"
          rules={[
            {
              required: true,
              message: t('enterExpectedValue'),
            },
          ]}
        >
          <Input
            addonBefore={t('value')}
            placeholder={t('enterExpectedValue')}
            addonAfter={<ReferenceStorage onChange={handleChange} />}
          />
        </Form.Item>
      </Form>
    </SharedDrawer>
  );
}

export default React.memo(AssertDrawer);
