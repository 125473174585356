import React, { useCallback, useEffect } from 'react';
import { Form, Typography } from 'antd';
import SharedDrawer from 'components/SharedDrawer';
import {
  editTargetAppDrawerVisibleSelector,
  mergedAppsSelector,
  selectedTargetAppSelector,
} from 'redux/Inspector/selector';
import {
  addAppInfo,
  hideEditTargetAppDrawer,
  setSelectedTargetApp,
} from 'redux/Inspector/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import TargetAppForm from './TargetAppForm';

const { Title } = Typography;

export default function EditTargetAppDrawer() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { validateFields, setFieldsValue } = form;
  const visible = useSelector(editTargetAppDrawerVisibleSelector);
  const selectedTargetApp = useSelector(selectedTargetAppSelector);
  const apps = useSelector(mergedAppsSelector);

  const appDetail = apps[selectedTargetApp];

  useEffect(() => {
    setFieldsValue(appDetail);
  }, [appDetail, setFieldsValue]);

  const handleAddAppInfo = useCallback(async () => {
    const info = await validateFields();
    dispatch(addAppInfo(info));
    dispatch(hideEditTargetAppDrawer());
  }, [dispatch, validateFields]);

  const handleHideAppDetail = useCallback(() => {
    dispatch(hideEditTargetAppDrawer());
    dispatch(setSelectedTargetApp({ appName: null }));
  }, [dispatch]);

  return (
    <SharedDrawer
      width={480}
      placement="left"
      visible={visible}
      okText={t('update')}
      cancelText={t('cancel')}
      onSubmit={handleAddAppInfo}
      onCancel={handleHideAppDetail}
      onClose={handleHideAppDetail}
      zIndex={1001}
    >
      <Title level={2}>{t('editTargetApp')}</Title>
      <TargetAppForm form={form} isEditForm />
    </SharedDrawer>
  );
}
