import React from 'react';
import PropTypes from 'prop-types';
import { Empty, Tooltip } from 'antd';

function ScreenshotImage({
  screenshot,
  screenshotInteractionMode,
  startPoint,
  endPoint,
}) {
  let swipeInstructions = null;
  if (screenshotInteractionMode === 'swipe') {
    if (!startPoint.x) {
      swipeInstructions = 'Click swipe start point';
    } else if (!endPoint.x) {
      swipeInstructions = 'Click swipe end point';
    }
  }

  if (screenshot) {
    return (
      <Tooltip
        visible={swipeInstructions}
        placement="top"
        title={swipeInstructions}
      >
        <img
          src={`data:image/gif;base64,${screenshot}`}
          id="screenshot"
          alt="screenshot"
          style={{ maxHeight: '100%', maxWidth: '100%' }}
        />
      </Tooltip>
    );
  }
  return (
    <Empty
      style={{
        height: '100%',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    />
  );
}

ScreenshotImage.propTypes = {
  screenshot: PropTypes.string,
  screenshotInteractionMode: PropTypes.string,
  startPoint: PropTypes.object,
  endPoint: PropTypes.object,
};

export default React.memo(ScreenshotImage);
