import React from 'react';
import memoize from 'lodash/memoize';
import { PropTypes } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import HighlighterRect from './HighlighterRect';

/**
 * Shows screenshot of running application and divs that highlight the elements' bounding boxes
 */
function HighlighterRects({
  screenshotInteractionMode,
  containerEl,
  source,
  searchedElementBounds,
  scrollAndFindDrawerVisible,
  selectedElement,
  onSelectHoveredElement,
  onUnselectHoveredElement,
  onSelectElement,
  onUnselectElement,
  hoveredElement,
  scaleRatio,
}) {
  // TODO: Recursive through the 'source' JSON and render a highlighter rect for each element
  const highlighterRects = [];

  let highlighterXOffset = 0;

  if (containerEl) {
    const screenshotEl = containerEl.querySelector('img');
    if (screenshotEl) {
      highlighterXOffset =
        screenshotEl.getBoundingClientRect().left -
        containerEl.getBoundingClientRect().left;
    }
  }

  const recursive = memoize((element, zIndex = 0) => {
    if (!element) {
      return;
    }
    highlighterRects.push(
      <HighlighterRect
        {...{
          key: element.path,
          selectedElement,
          onSelectHoveredElement,
          onUnselectHoveredElement,
          onSelectElement,
          onUnselectElement,
          hoveredElement,
          element,
          zIndex,
          scaleRatio,
          xOffset: highlighterXOffset,
        }}
      />
    );

    for (const childEl of element.children) {
      recursive(childEl, zIndex + 1);
    }
  });

  // TODO: If the use selected an element that they searched for, highlight that element
  if (!isEmpty(searchedElementBounds)) {
    const { location: elLocation, size: elSize } = searchedElementBounds;
    highlighterRects.push(
      <HighlighterRect
        {...{
          key: searchedElementBounds.path,
          selectedElement,
          onSelectHoveredElement,
          onUnselectHoveredElement,
          onSelectElement,
          onUnselectElement,
          hoveredElement,
          elLocation,
          elSize,
          scaleRatio,
          xOffset: highlighterXOffset,
        }}
      />
    );
  }

  // TODO: If we're tapping or swiping, show the 'crosshair' cursor style
  const screenshotStyle = {};
  if (
    screenshotInteractionMode === 'tap' ||
    screenshotInteractionMode === 'swipe'
  ) {
    screenshotStyle.cursor = 'crosshair';
  }

  // TODO: Don't show highlighter rects when Search Elements modal is open
  if (!scrollAndFindDrawerVisible) {
    recursive(source);
  }

  return <div>{highlighterRects}</div>;
}

HighlighterRects.propTypes = {
  screenshotInteractionMode: PropTypes.string,
  containerEl: PropTypes.object,
  source: PropTypes.object,
  searchedElementBounds: PropTypes.object,
  scrollAndFindDrawerVisible: PropTypes.bool,
  selectedElement: PropTypes.object,
  onSelectHoveredElement: PropTypes.func,
  onUnselectHoveredElement: PropTypes.func,
  onSelectElement: PropTypes.func,
  onUnselectElement: PropTypes.func,
  hoveredElement: PropTypes.object,
  scaleRatio: PropTypes.number,
};

export default HighlighterRects;
