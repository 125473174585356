import React, { useCallback } from 'react';
import {
  Typography,
  Form,
  Select,
  Descriptions,
  Button,
  Space,
  Tooltip,
  Popconfirm,
} from 'antd';
import SharedDrawer from 'components/SharedDrawer';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteAppInfo,
  hideSwitchAppDrawer,
  setSelectedTargetApp,
  showEditTargetAppDrawer,
  showNewTargetAppDrawer,
  switchApp,
} from 'redux/Inspector/slice';
import { currentTestSuiteSelector } from 'redux/TestSuites/selectors';
import {
  methodCallInProgressSelector,
  switchAppDrawerVisibleSelector,
  selectedTargetAppSelector,
  mergedAppsSelector,
} from 'redux/Inspector/selector';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const { Title } = Typography;

const SwitchAppDrawer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { setFieldsValue, validateFields } = form;
  const { platformName } = useSelector(currentTestSuiteSelector);
  const visible = useSelector(switchAppDrawerVisibleSelector);
  const loading = useSelector(methodCallInProgressSelector);
  const selectedTargetApp = useSelector(selectedTargetAppSelector);
  const apps = useSelector(mergedAppsSelector);

  const isAndroid = platformName === 'android';
  const isIOS = platformName === 'ios';
  const appDetail = apps[selectedTargetApp];

  const handleSwitch = useCallback(async () => {
    await validateFields();
    if (isIOS) {
      const { appName, bundleId } = appDetail;
      dispatch(switchApp([appName, bundleId]));
    } else if (isAndroid) {
      const { appName, appPackage, appActivity } = appDetail;
      dispatch(switchApp([appName, appPackage, appActivity]));
    }
  }, [appDetail, dispatch, isAndroid, isIOS, validateFields]);

  const handleClose = useCallback(() => {
    dispatch(hideSwitchAppDrawer());
    dispatch(setSelectedTargetApp({ appName: null }));
    setFieldsValue({ targetApp: null });
  }, [dispatch, setFieldsValue]);

  const handleShowNewTargetApp = useCallback(() => {
    dispatch(showNewTargetAppDrawer());
  }, [dispatch]);

  const handleChangeTargetApp = useCallback(
    (appName) => {
      dispatch(setSelectedTargetApp({ appName }));
    },
    [dispatch]
  );

  const options = Object.values(apps).map((app) => ({
    value: app.appName,
    label: app.appName,
  }));

  const handleDeleteApp = useCallback(() => {
    dispatch(deleteAppInfo({ appName: selectedTargetApp }));
    dispatch(setSelectedTargetApp({ appName: null }));
    setFieldsValue({ targetApp: null });
  }, []);

  const handleEditApp = useCallback(() => {
    dispatch(showEditTargetAppDrawer());
  }, [dispatch]);

  const handleFilter = useCallback(
    (input, option) =>
      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    []
  );

  return (
    <SharedDrawer
      width={480}
      visible={visible}
      placement="left"
      cancelText={t('cancel')}
      okText={t('switchToSelectedApp')}
      onCancel={handleClose}
      onSubmit={handleSwitch}
      submitLoading={loading}
    >
      <Title level={2}>{t('switchApp')}</Title>
      <Form form={form} layout="vertical" preserve={false} requiredMark={false}>
        <Form.Item label={t('switchToTargetApp')}>
          <div style={{ display: 'flex' }}>
            <Form.Item
              name="targetApp"
              rules={[
                {
                  required: true,
                  message: t('requiredTargetApp'),
                },
              ]}
              noStyle
            >
              <Select
                placeholder={t('appName')}
                allowClear
                dropdownMatchSelectWidth
                style={{ flex: 1 }}
                onChange={handleChangeTargetApp}
                options={options}
                showSearch
                filterOption={handleFilter}
              />
            </Form.Item>
            <Button style={{ marginLeft: 10 }} onClick={handleShowNewTargetApp}>
              {t('createTargetApp')}
            </Button>
          </div>
        </Form.Item>
      </Form>
      {appDetail && (
        <Descriptions
          title={t('appDetail')}
          bordered
          column={1}
          extra={
            <Space>
              <Tooltip title={t('editAppInfo')}>
                <Button
                  type="dashed"
                  icon={<EditOutlined />}
                  onClick={handleEditApp}
                  shape="circle"
                />
              </Tooltip>
              <Tooltip title={t('deleteAppInfo')}>
                <Popconfirm
                  title={t('confirmDelete')}
                  cancelText={t('cancel')}
                  onConfirm={handleDeleteApp}
                >
                  <Button
                    type="danger"
                    icon={<DeleteOutlined />}
                    shape="circle"
                  />
                </Popconfirm>
              </Tooltip>
            </Space>
          }
        >
          {appDetail.appName && (
            <Descriptions.Item label={t('appName')}>
              {appDetail.appName}
            </Descriptions.Item>
          )}
          {isIOS && (
            <Descriptions.Item label={t('bundleId')}>
              {appDetail.bundleId}
            </Descriptions.Item>
          )}
          {isAndroid && (
            <Descriptions.Item label={t('appPackage')}>
              {appDetail.appPackage}
            </Descriptions.Item>
          )}
          {isAndroid && (
            <Descriptions.Item label={t('appActivity')}>
              {appDetail.appActivity}
            </Descriptions.Item>
          )}
        </Descriptions>
      )}
    </SharedDrawer>
  );
};

export default SwitchAppDrawer;
