import { postTestCode } from '../redux/Download/testCode';

export default async function downloadSource(code, framework) {
  const response = await postTestCode({ code, framework });

  if (response?.data) {
    const fetchedData = await fetch(`data:*/*;base64,${response.data}`);
    const blob = await fetchedData.blob();

    const element = document.createElement('a');

    const url = URL.createObjectURL(blob, { type: 'application/zip' });

    element.setAttribute('href', url);

    element.setAttribute('download', `source_code_${framework}.zip`);

    element.style.display = 'none';

    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}
