/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint jsx-a11y/mouse-events-have-key-events: 0 */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { Spin, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  screenshotInteractionModeSelector,
  selectedElementSelector,
  hoveredElementSelector,
  swipeStartSelector,
  swipeEndSelector,
  searchedElementBoundsSelector,
  scrollAndFindDrawerVisibleSelector,
  tapPointSelector,
  selectTapPointFromSelector,
  selectSwipePointsFromSelector,
  windowSizeSelector,
  sourceSelector,
  screenshotSelector,
  loadingScreenshotSelector,
} from 'redux/Inspector/selector';

import { useSelector, useDispatch } from 'react-redux';
import {
  selectHoveredElement,
  unselectHoveredElement,
  selectElement,
  unselectElement,
  setSwipeStart,
  setSwipeEnd,
  showSwipeByTimesDrawer,
  setTapPoint,
  applyClientMethod,
} from 'redux/Inspector/slice';

import isEqual from 'react-fast-compare';
// import { parseCoordinates } from 'components/Inspector/shared';
import ScreenshotImage from 'components/Inspector/ScreenshotImage';
import './Screenshot.less';
import HighlighterRects from 'containers/Inspectors/HighlighterRects';

/**
 * Shows screenshot of running application and divs that highlight the elements' bounding boxes
 */
function Screenshot() {
  const containerEl = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [scaleRatio, setScaleRatio] = useState(1);
  const [currentX, setCurrentX] = useState(null);
  const [currentY, setCurrentY] = useState(null);

  const screenshotInteractionMode = useSelector(
    screenshotInteractionModeSelector
  );
  const windowSize = useSelector(windowSizeSelector);
  const source = useSelector(sourceSelector);

  const selectedElement = useSelector(selectedElementSelector);
  const hoveredElement = useSelector(hoveredElementSelector);
  const startPoint = useSelector(swipeStartSelector);
  const { x: startX, y: startY } = startPoint;
  const endPoint = useSelector(swipeEndSelector);
  const { x: endX, y: endY } = endPoint;
  const screenshot = useSelector(screenshotSelector);
  const searchedElementBounds = useSelector(searchedElementBoundsSelector);
  const scrollAndFindDrawerVisible = useSelector(
    scrollAndFindDrawerVisibleSelector
  );
  const { x: tapX, y: tapY } = useSelector(tapPointSelector);
  const selectTapPointFrom = useSelector(selectTapPointFromSelector);
  const selectSwipePointsFrom = useSelector(selectSwipePointsFromSelector);
  const loading = useSelector(loadingScreenshotSelector);

  const handleSelectHoveredElement = useCallback(
    (path) => {
      dispatch(selectHoveredElement({ path }));
    },
    [dispatch]
  );
  const handleUnselectHoveredElement = useCallback(
    (path) => {
      dispatch(unselectHoveredElement({ path }));
    },
    [dispatch]
  );
  const handleSelectElement = useCallback(
    (path) => {
      dispatch(selectElement({ path }));
    },
    [dispatch]
  );
  const handleUnselectElement = useCallback(() => {
    dispatch(unselectElement());
  }, [dispatch]);

  /**
   * Calculates the ratio that the image is being scaled by
   */
  const updateScaleRatio = useCallback(() => {
    setTimeout(() => {
      const screenshotElement = document?.getElementById('screenshot');
      // now update scale ratio
      if (
        windowSize &&
        screenshotElement &&
        screenshotElement?.offsetWidth !== 0
      ) {
        setScaleRatio(windowSize.width / screenshotElement.offsetWidth);
      }
    }, 500);
  }, [windowSize]);

  const debouncedUpdateScaleRatio = useRef(debounce(updateScaleRatio, 1000))
    .current;

  useEffect(() => {
    updateScaleRatio();
  }, [updateScaleRatio]);

  useEffect(() => {
    // When DOM is ready, calculate the image scale ratio
    // and re-calculate it whenever the window is resized
    window.addEventListener('resize', debouncedUpdateScaleRatio);
    return () => {
      window.removeEventListener('resize', debouncedUpdateScaleRatio);
    };
  }, [debouncedUpdateScaleRatio]);

  const handleTap = useCallback(() => {
    dispatch(setTapPoint({ x: currentX, y: currentY }));
    if (selectTapPointFrom !== 'action-flow') {
      dispatch(
        applyClientMethod({
          methodName: 'tap',
          args: [currentX, currentY],
        })
      );
    }
  }, [currentX, currentY, dispatch, selectTapPointFrom]);

  // TODO: Get angle of swipe path
  function calculateAngleDegrees(x, y) {
    const angleDegrees = (Math.atan2(y, x) * 180) / Math.PI;
    return Math.round(angleDegrees * 10) / 10;
  }

  const deltaX = endX ? endX - startX : currentX - startX;
  const deltaY = endY ? endY - startY : currentY - startY;

  const angle = calculateAngleDegrees(deltaX, deltaY);

  const handleSwipe = useCallback(async () => {
    if ((!startX && !endX) || (startX && endX)) {
      dispatch(setSwipeStart({ x: currentX, y: currentY }));
      dispatch(setSwipeEnd({ x: null, y: null }));
    } else if (!endX) {
      dispatch(setSwipeEnd({ x: currentX, y: currentY }));
      if (selectSwipePointsFrom === 'swipe-drawer') {
        dispatch(showSwipeByTimesDrawer());
      }
    }
  }, [currentX, currentY, dispatch, endX, selectSwipePointsFrom, startX]);

  const handleClickScreenshot = useCallback(() => {
    if (screenshotInteractionMode === 'tap') {
      handleTap();
    } else if (screenshotInteractionMode === 'swipe') {
      handleSwipe();
    }
  }, [handleSwipe, handleTap, screenshotInteractionMode]);

  const handleMouseMove = (e) => {
    if (screenshotInteractionMode !== 'select') {
      const { offsetX, offsetY } = e.nativeEvent;
      const _x = offsetX * scaleRatio;
      const _y = offsetY * scaleRatio;
      setCurrentX(Math.round(_x));
      setCurrentY(Math.round(_y));
    }
  };

  const handleMouseOut = () => {
    setCurrentX(null);
    setCurrentY(null);
  };

  // TODO: If we're tapping or swiping, show the 'crosshair' cursor style
  const screenshotStyle = {};
  if (
    screenshotInteractionMode === 'tap' ||
    screenshotInteractionMode === 'swipe'
  ) {
    screenshotStyle.cursor = 'crosshair';
  }

  const renderExtra = () =>
    currentX !== null &&
    screenshot && (
      <p>
        X: {currentX} | Y: {currentY}
      </p>
    );

  // TODO: Show the screenshot and highlighter rects.
  // TODO: Show loading indicator if a method call is in progress.
  return (
    <Card
      title={t('screenshot')}
      id="screenshot-card"
      size="small"
      extra={renderExtra()}
      style={{
        height: '100%',
        width: '100%',
        flex: 1,
      }}
      bodyStyle={{
        height: 'calc(100% - 39px)',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Spin size="large" spinning={loading}>
        <div
          ref={containerEl}
          style={screenshotStyle}
          onClick={handleClickScreenshot}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseOut}
          className="screenshot-box"
        >
          <ScreenshotImage
            {...{
              screenshot,
              screenshotInteractionMode,
              startPoint,
              endPoint,
            }}
          />
          {screenshotInteractionMode === 'select' && containerEl && (
            <HighlighterRects
              {...{
                screenshotInteractionMode,
                containerEl: containerEl.current,
                source,
                searchedElementBounds,
                scrollAndFindDrawerVisible,
                selectedElement,
                onSelectHoveredElement: handleSelectHoveredElement,
                onUnselectHoveredElement: handleUnselectHoveredElement,
                onSelectElement: handleSelectElement,
                onUnselectElement: handleUnselectElement,
                hoveredElement,
                scaleRatio,
              }}
            />
          )}
          {screenshotInteractionMode === 'tap' &&
            selectTapPointFrom === 'action-flow' && (
              <svg className="tap-svg">
                <circle cx={tapX / scaleRatio} cy={tapY / scaleRatio} />
              </svg>
            )}
          {screenshotInteractionMode === 'swipe' && (
            <svg className="swipe-svg">
              <defs>
                <linearGradient
                  id="dynamic"
                  x1={startX / scaleRatio}
                  y1={startY / scaleRatio}
                  x2={currentX / scaleRatio}
                  y2={currentY / scaleRatio}
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" />
                  <stop offset="1" />
                </linearGradient>
                <linearGradient
                  id="static"
                  x1={startX / scaleRatio}
                  y1={startY / scaleRatio}
                  x2={endX / scaleRatio}
                  y2={endY / scaleRatio}
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" />
                  <stop offset="1" />
                </linearGradient>
              </defs>
              {startX && !endX && (
                <g>
                  <circle cx={startX / scaleRatio} cy={startY / scaleRatio} />
                  <text
                    x={startX / scaleRatio + 30}
                    y={startY / scaleRatio}
                    textAnchor="middle"
                    fill="red"
                  >
                    {angle} &#176;
                  </text>
                </g>
              )}
              {startX && !endX && currentX && (
                <line
                  stroke="url(#dynamic)"
                  x1={startX / scaleRatio}
                  y1={startY / scaleRatio}
                  x2={currentX / scaleRatio}
                  y2={currentY / scaleRatio}
                />
              )}
              {startX && endX && (
                <line
                  stroke="url(#static)"
                  x1={startX / scaleRatio}
                  y1={startY / scaleRatio}
                  x2={endX / scaleRatio}
                  y2={endY / scaleRatio}
                />
              )}
            </svg>
          )}
        </div>
      </Spin>
    </Card>
  );
}

export default React.memo(Screenshot, isEqual);
