import React, { useCallback } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import SharedDrawer from 'components/SharedDrawer';
import {
  elementDetailDrawerVisibleSelector,
  selectedElementSelector,
  sourceXMLSelector,
} from 'redux/Inspector/selector';
import { useSelector, useDispatch } from 'react-redux';
import { hideElementDetailDrawer } from 'redux/Inspector/slice';
import { Descriptions, Typography } from 'antd';
import { toPairs } from 'lodash';
import { getLocators } from 'components/Inspector/shared';

const { Title, Text } = Typography;

const ElementDetailDrawer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const visible = useSelector(elementDetailDrawerVisibleSelector);
  const selectedElement = useSelector(selectedElementSelector);

  const { attributes, xpath } = selectedElement || {};

  // Get the data for the attributes table
  // const attrArray = attributes.filter(([key]) => key !== 'path');
  const attrArray = toPairs(attributes).filter(([key]) => key !== 'path');
  const attrSource = attrArray.map(([key, value]) => ({
    key,
    value,
    name: key,
  }));

  const sourceXML = useSelector(sourceXMLSelector);

  // Get the data for the strategies table
  const selectorSource = toPairs(getLocators(attributes, sourceXML)).map(
    ([key, selector]) => ({
      key,
      selector,
      find: key,
    })
  );

  // Add XPath to the data source as well
  if (xpath) {
    selectorSource.push({
      key: 'xpath',
      find: 'xpath',
      selector: xpath,
    });
  }

  const handleCancel = useCallback(() => {
    dispatch(hideElementDetailDrawer());
  }, [dispatch]);

  return (
    <SharedDrawer
      visible={visible}
      title={t('elementDetail')}
      onCancel={handleCancel}
      cancelText={t('cancel')}
      placement="left"
    >
      <Title>{t('elementDetail')}</Title>
      <Descriptions title={t('selectors')} bordered column={1}>
        {selectorSource.map(({ key, find, selector }) => (
          <Descriptions.Item key={key} label={find}>
            <Text copyable>{selector}</Text>
          </Descriptions.Item>
        ))}
      </Descriptions>
      <Descriptions
        title={t('attributes')}
        bordered
        column={2}
        style={{ marginTop: 50 }}
      >
        {attrSource.map(({ key, value, name }) => (
          <Descriptions.Item key={key} label={name}>
            <Text copyable>{value}</Text>
          </Descriptions.Item>
        ))}
      </Descriptions>
    </SharedDrawer>
  );
};

export default ElementDetailDrawer;
