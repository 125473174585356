import React, { useCallback } from 'react';
import SharedDrawer from 'components/SharedDrawer';
import isEqual from 'react-fast-compare';
import { useSelector, useDispatch } from 'react-redux';
import {
  sessionLoadingSelector,
  clientDevicesDrawerVisibleSelector,
} from 'redux/Inspector/selector';
import {
  clientDevicesByTypeFilterSelector,
  currentDeviceTypeSelector,
  currentPlatformNameSelector,
  selectedDeviceSelector,
  localClientIdSelector,
  connectedClientIdsFilterSelector,
} from 'redux/ClientDevices/selectors';
import { currentTestCaseSelector } from 'redux/TestCases/selectors';
import { setSelectedDevice } from 'redux/ClientDevices/slice';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useTranslation } from 'react-i18next';
import ClientDevices, { STATUS_RUNNING } from 'components/ClientDevices';
import { createNewAppiumSession } from 'redux/Inspector/slice';

const ClientDeviceSelectionDrawer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { navigate } = useI18next();
  const { id } = useSelector(currentTestCaseSelector);
  const loading = useSelector(sessionLoadingSelector);
  const platformName = useSelector(currentPlatformNameSelector);
  const deviceType = useSelector(currentDeviceTypeSelector);
  const localClientId = useSelector(localClientIdSelector);
  const clientIdsFilter = useSelector(connectedClientIdsFilterSelector);
  const clientIds = clientIdsFilter(true);
  const clientDevicesFilter = useSelector(clientDevicesByTypeFilterSelector);
  const clientDevices = clientDevicesFilter(
    localClientId,
    platformName,
    deviceType
  );
  const selectedDevice = useSelector(selectedDeviceSelector);
  const visible = useSelector(clientDevicesDrawerVisibleSelector);

  const handleSubmit = useCallback(() => {
    if (selectedDevice) {
      dispatch(createNewAppiumSession());
    }
  }, [dispatch, selectedDevice]);

  const handleSelectDevice = useCallback(
    (_, selectedDevices) => {
      dispatch(setSelectedDevice(selectedDevices[0]));
    },
    [dispatch]
  );

  const handleClose = useCallback(() => {
    navigate(`/dashboard/test-cases/${id}`);
  }, [navigate, id]);

  const handleGetCheckboxProps = useCallback(
    ({ serverStatus }) => ({
      disabled: serverStatus !== STATUS_RUNNING, // Column configuration not to be checked
    }),
    []
  );

  const rowSelection = {
    type: 'radio',
    onChange: handleSelectDevice,
    getCheckboxProps: handleGetCheckboxProps,
  };

  return (
    <SharedDrawer
      visible={visible}
      title={t('selectDevices')}
      okText={t('connectToClientDevice')}
      submitButtonDisabled={!selectedDevice}
      onSubmit={handleSubmit}
      cancelText={t('backToTestCase')}
      onCancel={handleClose}
      submitLoading={loading}
    >
      <ClientDevices
        clientDevices={clientDevices}
        localClientId={localClientId}
        rowSelection={rowSelection}
        clientIds={clientIds}
      />
    </SharedDrawer>
  );
};

export default React.memo(ClientDeviceSelectionDrawer, isEqual);
