import React, { useCallback } from 'react';
import { Form, Input, Typography } from 'antd';
import SharedDrawer from 'components/SharedDrawer';
import { trimProps } from 'utils/validation';
import { newTargetAppDrawerVisibleSelector } from 'redux/Inspector/selector';
import { addAppInfo, hideNewTargetAppDrawer } from 'redux/Inspector/slice';
import { currentTestSuiteSelector } from 'redux/TestSuites/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const { Title } = Typography;

export default function NewTargetAppDrawer() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { validateFields, setFieldsValue } = form;
  const visible = useSelector(newTargetAppDrawerVisibleSelector);
  const { platformName } = useSelector(currentTestSuiteSelector);
  const isIOS = platformName === 'ios';
  const isAndroid = platformName === 'android';

  const handleAddAppInfo = useCallback(async () => {
    const info = await validateFields();
    dispatch(addAppInfo(info));
    dispatch(hideNewTargetAppDrawer());
  }, [dispatch, validateFields]);

  const handleHideAppDetail = useCallback(() => {
    dispatch(hideNewTargetAppDrawer());
  }, [dispatch]);

  return (
    <SharedDrawer
      width={480}
      placement="left"
      visible={visible}
      okText={t('add')}
      cancelText={t('cancel')}
      onSubmit={handleAddAppInfo}
      onCancel={handleHideAppDetail}
      onClose={handleHideAppDetail}
      destroyOnClose
    >
      <Title level={2}>{t('createTargetApp')}</Title>
      <Form form={form} preserve={false}>
        <Form.Item
          name="appName"
          rules={[
            {
              required: true,
              message: t('requiredAppName'),
              whitespace: true,
            },
            {
              max: 30,
              message: t('validate.max', {
                key: t('appName'),
                value: 30,
              }),
            },
          ]}
          {...trimProps(setFieldsValue)}
        >
          <Input addonBefore={t('appName')} placeholder={t('appName')} />
        </Form.Item>
        {isIOS && (
          <Form.Item
            name="bundleId"
            rules={[
              {
                required: true,
                message: t('requiredBundleId'),
                whitespace: true,
              },
              {
                max: 155,
                message: t('validate.max', {
                  key: 'Bundle Id',
                  value: 155,
                }),
              },
            ]}
            {...trimProps(setFieldsValue)}
          >
            <Input addonBefore={t('bundleId')} placeholder="com.example.app" />
          </Form.Item>
        )}
        {isAndroid && (
          <>
            <Form.Item
              name="appPackage"
              rules={[
                {
                  required: true,
                  message: t('requiredAppPackage'),
                  whitespace: true,
                },
                {
                  max: 155,
                  message: t('validate.max', {
                    key: t('appPackage'),
                    value: 155,
                  }),
                },
              ]}
              {...trimProps(setFieldsValue)}
            >
              <Input
                addonBefore={t('appPackage')}
                placeholder="com.google.android.apps.maps"
              />
            </Form.Item>
            <Form.Item
              name="appActivity"
              rules={[
                {
                  required: true,
                  message: t('requiredAppActivity'),
                  whitespace: true,
                },
                {
                  max: 155,
                  message: t('validate.max', {
                    key: t('appActivity'),
                    value: 155,
                  }),
                },
              ]}
              {...trimProps(setFieldsValue)}
            >
              <Input
                addonBefore={t('appActivity')}
                placeholder="com.google.android.maps.MapsActivity"
              />
            </Form.Item>
            <Form.Item>
              <a
                href="/dashboard/tutorial#how-to-find-app-package-and-app-activity-of-your-android-app"
                target="_blank"
              >
                {t('howToGetAppActivity')}
              </a>
            </Form.Item>
          </>
        )}
      </Form>
    </SharedDrawer>
  );
}
