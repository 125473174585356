import React, { useCallback, useState } from 'react';
import { Button, Popover, Select, Space, Tooltip } from 'antd';
import Icon, {
  SearchOutlined,
  ArrowLeftOutlined,
  SelectOutlined,
  ReloadOutlined,
  EllipsisOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import SvgIcons from 'images/svg';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  loadingScreenshotSelector,
  screenshotInteractionModeSelector,
} from 'redux/Inspector/selector';
import {
  clearSwipeAction,
  selectScreenshotInteractionMode,
  showScrollAndFindDrawer,
  applyClientMethod,
  recordSleep,
  showSwipeByTimesDrawer,
  pressHomeButton,
  showAdjustVolumeDrawer,
  showSwitchAppDrawer,
} from 'redux/Inspector/slice';
import './ScreenActions.less';

const { Option } = Select;

export default function ScreenActions() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedAction, setSelectedAction] = useState();
  const [otherVisible, setOtherVisible] = useState(false);
  const [needSetup, setNeedSetup] = useState(false);

  const screenshotInteractionMode = useSelector(
    screenshotInteractionModeSelector
  );
  const loading = useSelector(loadingScreenshotSelector);

  const handleBack = () => {
    dispatch(applyClientMethod({ methodName: 'back' }));
  };

  const handleSleep = () => {
    dispatch(recordSleep(3));
  };

  const handleChangeScreenshotInteractionMode = useCallback(
    (mode) => {
      dispatch(clearSwipeAction()); // When the action changes, reset the swipe action
      dispatch(selectScreenshotInteractionMode({ mode }));
    },
    [dispatch]
  );

  const handleShowScrollAndFindDrawer = () => {
    dispatch(showScrollAndFindDrawer());
  };

  const handleSwipe = useCallback(() => {
    handleChangeScreenshotInteractionMode('swipe');
    dispatch(showSwipeByTimesDrawer());
  }, [dispatch, handleChangeScreenshotInteractionMode]);

  const handleRefresh = () => {
    dispatch(applyClientMethod({ methodName: 'source' }));
  };

  const handleFilter = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handleChangeAction = (value) => {
    setSelectedAction(value);
    switch (value) {
      case 'adjustVolume':
        setNeedSetup(true);
        break;
      default:
        setNeedSetup(false);
        break;
    }
  };

  const handleGoHome = () => {
    dispatch(pressHomeButton());
  };

  const handleOpenAdjustVolumeDrawer = () => {
    dispatch(showAdjustVolumeDrawer());
  };

  const handleExecuteAction = () => {
    switch (selectedAction) {
      case 'adjustVolume':
        handleOpenAdjustVolumeDrawer();
        setNeedSetup(true);
        break;
      default:
        break;
    }
    setSelectedAction(null);
    setOtherVisible(false);
    setNeedSetup(false);
  };

  const handleShowSwitchAppDrawer = useCallback(() => {
    dispatch(showSwitchAppDrawer());
  }, [dispatch]);

  const others = (
    <div className="action-row-2">
      <Select
        size="small"
        allowClear
        style={{ flex: 1 }}
        placeholder={t('searchOtherActions')}
        onChange={handleChangeAction}
        filterOption={handleFilter}
        dropdownMatchSelectWidth={false}
        value={selectedAction}
      >
        <Option value="adjustVolume" disabled={loading}>
          <Icon component={SvgIcons.VolumeUp} /> {t('adjustVolume')}
        </Option>
      </Select>
      {selectedAction && (
        <Button
          type="primary"
          size="small"
          onClick={handleExecuteAction}
          disabled={!selectedAction}
          style={{ marginLeft: 2 }}
        >
          {needSetup ? t('setUp') : t('execute')}
        </Button>
      )}
    </div>
  );

  const handleOtherPopover = (visible) => {
    setOtherVisible(visible);
  };

  return (
    <Space direction="vertical" className="screen-actions-container">
      <Tooltip title={t('pressHomeBtnTooltip')} placement="left">
        <Button
          icon={<HomeOutlined />}
          onClick={handleGoHome}
          ghost
          type="primary"
          disabled={loading}
        />
      </Tooltip>
      <Tooltip title={t('refreshTooltip')} placement="left">
        <Button
          id="btnReload"
          icon={<ReloadOutlined />}
          onClick={handleRefresh}
          ghost
          type="primary"
          disabled={loading}
        />
      </Tooltip>
      <Tooltip title={t('swipeBackTooltip')} placement="left">
        <Button
          id="btnGoBack"
          icon={<ArrowLeftOutlined />}
          onClick={handleBack}
          type="primary"
          ghost
          disabled={loading}
        />
      </Tooltip>
      <Tooltip title={t('selectElements')} placement="left">
        <Button
          value="select"
          icon={<SelectOutlined />}
          type="primary"
          ghost={screenshotInteractionMode !== 'select'}
          onClick={() => handleChangeScreenshotInteractionMode('select')}
          disabled={loading}
        />
      </Tooltip>
      <Tooltip title={t('swipeByCoordinates')} placement="left">
        <Button
          value="swipe"
          icon={<Icon component={SvgIcons.Swipe} />}
          type="primary"
          onClick={handleSwipe}
          ghost={screenshotInteractionMode !== 'swipe'}
          disabled={loading}
        />
      </Tooltip>
      <Tooltip title={t('tapByCoordinates')} placement="left">
        <Button
          value="tap"
          type="primary"
          ghost={screenshotInteractionMode !== 'tap'}
          onClick={() => handleChangeScreenshotInteractionMode('tap')}
          disabled={loading}
          icon={<Icon component={SvgIcons.Touch} />}
        />
      </Tooltip>
      <Tooltip title={t('sleepTooltip')} placement="left">
        <Button
          onClick={handleSleep}
          type="primary"
          ghost
          icon={<Icon component={SvgIcons.Sleep} />}
        />
      </Tooltip>
      <Tooltip title={t('search')} placement="left">
        <Button
          icon={<SearchOutlined />}
          type="primary"
          ghost
          onClick={handleShowScrollAndFindDrawer}
          disabled={loading}
        />
      </Tooltip>
      <Tooltip title={t('switchAppTooltip')} placement="left">
        <Button
          icon={<Icon component={SvgIcons.Switcher} />}
          type="primary"
          ghost
          disabled={loading}
          onClick={handleShowSwitchAppDrawer}
        />
      </Tooltip>
      <Tooltip title={t('otherActions')} placement="left">
        <Popover
          placement="bottomRight"
          content={others}
          trigger="click"
          visible={otherVisible}
          onVisibleChange={handleOtherPopover}
        >
          <Button icon={<EllipsisOutlined />} type="primary" ghost />
        </Popover>
      </Tooltip>
    </Space>
  );
}
