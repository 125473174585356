import React, { useCallback } from 'react';
import { Input, Form, Checkbox, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import SharedDrawer from 'components/SharedDrawer';
import ReferenceStorage from 'containers/ReferenceStorage';
import { applyClientMethod, hideSendKeysDrawer } from 'redux/Inspector/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectedElementIdSelector,
  sendKeysDrawerVisibleSelector,
} from 'redux/Inspector/selector';

const { Item } = Form;
const { Text } = Typography;

function SendKeysDrawer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { setFieldsValue, validateFields, resetFields } = form;

  const elementId = useSelector(selectedElementIdSelector);
  const visible = useSelector(sendKeysDrawerVisibleSelector);

  const handleChange = useCallback(
    (value, variable) => {
      setFieldsValue({ value, variable });
    },
    [setFieldsValue]
  );

  const handleClose = useCallback(() => {
    resetFields();
    dispatch(hideSendKeysDrawer());
  }, [dispatch, resetFields]);

  const handleSubmit = useCallback(async () => {
    const { variable, value, action } = await validateFields();

    await dispatch(
      applyClientMethod({
        methodName: 'sendKeys',
        elementId,
        args: [value, variable, action],
      })
    );
    handleClose();
  }, [dispatch, elementId, handleClose, validateFields]);

  const handleChangeValue = useCallback(() => {
    setFieldsValue({ variable: null });
  }, [setFieldsValue]);

  return (
    <SharedDrawer
      title={t('input')}
      width={480}
      visible={visible}
      placement="left"
      cancelText={t('cancel')}
      okText={t('submit')}
      onCancel={handleClose}
      onSubmit={handleSubmit}
    >
      <Form form={form} layout="vertical">
        <Item name="variable">
          <Input addonBefore={t('variableName')} disabled />
        </Item>
        <Item
          name="value"
          rules={[
            {
              required: true,
              message: t('enterExpectedValue'),
            },
          ]}
        >
          <Input
            addonBefore={t('value')}
            placeholder={t('enterExpectedValue')}
            addonAfter={<ReferenceStorage onChange={handleChange} />}
            onChange={handleChangeValue}
          />
        </Item>
        <Form.Item name="action" valuePropName="checked" initialValue>
          <Checkbox>
            <Trans i18nKey="pressEnterAfterSendKeys">
              <Text keyboard />
            </Trans>
          </Checkbox>
        </Form.Item>
      </Form>
    </SharedDrawer>
  );
}

export default React.memo(SendKeysDrawer);
