import React, { useCallback } from 'react';
import { Button, InputNumber, Form, Input, Tag } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  scrollWithSpecificCoordinates,
  selectSwipePoints,
  hideSwipeByTimesDrawer,
} from 'redux/Inspector/slice';
import isEqual from 'react-fast-compare';
import CustomInput from 'components/Styled/CustomInput';
import SharedDrawer from 'components/SharedDrawer';
import {
  swipeStartSelector,
  swipeEndSelector,
  swipeByTimesDrawerVisibleSelector,
  methodCallInProgressSelector,
} from 'redux/Inspector/selector';

const { Item } = Form;

function SwipeByTimesDrawer() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { validateFields, resetFields } = form;

  const { t } = useTranslation();

  const loading = useSelector(methodCallInProgressSelector);
  const startPoint = useSelector(swipeStartSelector);
  const endPoint = useSelector(swipeEndSelector);
  const visible = useSelector(swipeByTimesDrawerVisibleSelector);

  const handleClose = useCallback(() => {
    resetFields();
    dispatch(hideSwipeByTimesDrawer());
  }, [dispatch, resetFields]);

  const handleSubmit = useCallback(async () => {
    try {
      if (!startPoint.x || !endPoint.x) {
        return;
      }
      const { maxSwipe } = await validateFields();
      // TODO: Not allow submitting unless select start and end coordinates.
      dispatch(
        scrollWithSpecificCoordinates({
          start: startPoint,
          end: endPoint,
          maxSwipe,
        })
      );
    } catch (error) {
      console.error('handleSwipeByTimes error', error);
    }
  }, [dispatch, endPoint, startPoint, validateFields]);

  const handleSelectSwipePoint = useCallback(() => {
    dispatch(selectSwipePoints());
  }, [dispatch]);

  return (
    <SharedDrawer
      title={t('swipeByTimes')}
      width={520}
      visible={visible}
      placement="left"
      cancelText={t('cancel')}
      okText={t('swipe')}
      submitLoading={loading}
      onSubmit={handleSubmit}
      onCancel={handleClose}
    >
      <Form form={form}>
        <Form.Item name="submitType" noStyle initialValue="swipeToVisible">
          <Input style={{ display: 'none' }} />
        </Form.Item>
        <Button onClick={handleSelectSwipePoint} style={{ marginBottom: 5 }}>
          {startPoint.x && endPoint.x
            ? t('reselectSwipePoints')
            : t('selectSwipePoints')}
        </Button>
        {(!startPoint.x || !endPoint.x) && (
          <Item validateStatus="error" help={t('selectCoordinates')} />
        )}
        <Form.Item>
          <Trans
            i18nKey="swipeFromStartToEnd"
            values={{
              startX: startPoint.x || '_',
              startY: startPoint.y || '_',
              endX: endPoint.x || '_',
              endY: endPoint.y || '_',
            }}
          >
            <Tag color="green" />
            <Tag color="magenta" />
          </Trans>
        </Form.Item>
        <Form.Item>
          <CustomInput label={t('swipe')}>
            <Form.Item
              noStyle
              name="maxSwipe"
              rules={[
                {
                  required: true,
                  message: t('requiredMaxSwipe'),
                },
              ]}
              initialValue={3}
            >
              <InputNumber min={1} />
            </Form.Item>
          </CustomInput>
        </Form.Item>
      </Form>
    </SharedDrawer>
  );
}

export default React.memo(SwipeByTimesDrawer, isEqual);
