/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useCallback } from 'react';
import SEO from 'components/SEO';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Screenshot from 'containers/Inspectors/Screenshot';
import RecordedActions from 'containers/Inspectors/RecordedActions';
import { setSelectedDevice } from 'redux/ClientDevices/slice';
import ScrollAndFindDrawer from 'containers/ScrollAndFindDrawer';
import AssertDrawer from 'containers/AssertDrawer';
import SendKeysDrawer from 'containers/SendKeysDrawer';
import ClientDeviceSelectionDrawer from 'containers/ClientDeviceSelectionDrawer';
import {
  getInspectorData,
  showClientDevicesDrawer,
} from 'redux/Inspector/slice';
import debounce from 'lodash/debounce';
import { screenshotSelector } from 'redux/Inspector/selector';
import { useMatch } from '@reach/router';
import { I18nextContext } from 'gatsby-plugin-react-i18next';
import ScreenActions from 'containers/Inspectors/ScreenActions';
import SwipeByTimesDrawer from './SwipeByTimesDrawer';
import ElementDetailDrawer from './ElementDetailDrawer';
import SwitchAppDrawer from './SwitchAppDrawer';
import './InspectorPage.less';
import 'containers/SharedPage/index.less';

// TODO: Without this one, will occur some bug relating to layout.
import 'styles/app.global.css';
import 'styles/github-gist-theme.global.css';
import AdjustVolumeDrawer from './AdjustVolumeDrawer';
import NewTargetAppDrawer from './TargetApp/NewTargetAppDrawer';
import EditTargetAppDrawer from './TargetApp/EditTargetAppDrawer';

const SCREENSHOT_MAX_WIDTH = 500;

export default function InspectorPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { language } = React.useContext(I18nextContext);

  const match = useMatch(
    // eslint-disable-next-line prettier/prettier
    `${language !== 'en' ? `/${language}` : ''}/dashboard/inspector/:testCaseId`
  );

  const { testCaseId } = match || {};

  const screenshot = useSelector(screenshotSelector);

  const updateImageSize = useCallback(() => {
    const screenshotBox = document.querySelector('#screenshot-container');
    const img = document.querySelector('#screenshot-container img#screenshot');
    if (!img) {
      return;
    }
    const imgRect = img.getBoundingClientRect();
    const screenshotBoxRect = screenshotBox.getBoundingClientRect();

    if (imgRect.width < screenshotBoxRect.width) {
      screenshotBox.style.maxWidth = `${imgRect.width + 8}px`;
      screenshotBox.style.flexBasis = `${imgRect.width + 8}px`;
    } else if (imgRect.height < screenshotBoxRect.height) {
      // get what the img width would be if it fills screenshot box height
      const attemptedWidth =
        (screenshotBoxRect.height / imgRect.height) * imgRect.width;
      screenshotBox.style.maxWidth =
        attemptedWidth > SCREENSHOT_MAX_WIDTH
          ? `${SCREENSHOT_MAX_WIDTH - 8}px`
          : `${attemptedWidth - 8}px`;
      screenshotBox.style.flexBasis =
        attemptedWidth > SCREENSHOT_MAX_WIDTH
          ? `${SCREENSHOT_MAX_WIDTH - 8}px`
          : `${attemptedWidth - 8}px`;
    }
  }, []);

  const debounceUpdateImageSize = debounce(updateImageSize, 50);

  useEffect(() => {
    debounceUpdateImageSize();
  }, [debounceUpdateImageSize, screenshot]); // Resize every screenshot updated

  useEffect(() => {
    window.addEventListener('resize', debounceUpdateImageSize);
    return () => {
      window.removeEventListener('resize', debounceUpdateImageSize);
    };
  }, [debounceUpdateImageSize]);

  useEffect(() => {
    dispatch(showClientDevicesDrawer());
  }, [dispatch]);

  useEffect(() => {
    if (testCaseId) {
      dispatch(getInspectorData({ testCaseId }));
    }
  }, [dispatch, testCaseId]);

  useEffect(() => {
    dispatch(setSelectedDevice(null));
  }, [dispatch]);

  return (
    <div className="inspector-container">
      <SEO title={t('inspector')} description={t('SEODescription')} />
      <div id="recorded-actions-container">
        <RecordedActions />
      </div>
      <div id="screenshot-container">
        <Screenshot />
      </div>
      <div className="screen-actions-container">
        <ScreenActions />
      </div>
      <ClientDeviceSelectionDrawer />
      <ScrollAndFindDrawer />
      <SendKeysDrawer />
      <AssertDrawer />
      <SwipeByTimesDrawer />
      <ElementDetailDrawer />
      <AdjustVolumeDrawer />
      <SwitchAppDrawer />
      <EditTargetAppDrawer />
      <NewTargetAppDrawer />
    </div>
  );
}
