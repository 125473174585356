import React, { useCallback, useState } from 'react';
import { Tree, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  sourceSelector,
  sourceErrorSelector,
  expandedPathsSelector,
  selectedElementSelector,
} from 'redux/Inspector/selector';
import {
  selectElement,
  unselectElement,
  setExpandedPaths,
} from 'redux/Inspector/slice';

import uniq from 'lodash/uniq';
import './Source.less';

const IMPORTANT_ATTRS = [
  'name',
  'content-desc',
  'resource-id',
  'AXDescription',
  'AXIdentifier',
];

const { Search } = Input;

/**
 * Shows the 'source' of the app as a Tree
 */
export default function Source() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const source = useSelector(sourceSelector);
  const sourceError = useSelector(sourceErrorSelector);
  const expandedPaths = useSelector(expandedPathsSelector);
  const selectedElement = useSelector(selectedElementSelector);
  const [foundPaths, setFoundPaths] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(false);

  const { path } = selectedElement;

  const handleSetExpandedPaths = useCallback(
    (paths) => {
      dispatch(setExpandedPaths({ paths }));
    },
    [dispatch]
  );

  const getFormattedTitle = (el) => {
    const { tagName, attributes, path: _path } = el;
    const attrs = [];
    let attrsText = '';

    for (const attr of IMPORTANT_ATTRS) {
      if (attributes[attr]) {
        attrs.push(
          <span key={attr}>
            &nbsp;
            <i className="source-attribute-name">{attr}</i>=
            <span className="source-attribute-value">
              &quot;
              {attributes[attr]}
              &quot;
            </span>
          </span>
        );

        attrsText = attrsText.concat(attr, '=', '"', attributes[attr], '"');
      }
    }

    const titleText = attrsText ? `<${tagName} ${attrsText}>` : `<${tagName}>`;
    const className = foundPaths.includes(_path) ? 'highlight' : '';
    return {
      titleTag: (
        <span className={className}>
          &lt;
          <b className="source-tag">{tagName}</b>
          {attrs}
          &gt;
        </span>
      ),
      titleText,
    };
  };

  const getParentKey = (key) => {
    const splittedKey = key.split('.');
    splittedKey.pop();
    return splittedKey.join('.');
  };

  /**
   * Binds to antd Tree onSelect. If an item is being unselected, path is undefined
   * otherwise 'path' refers to the element's path.
   */
  const handleSelectElement = useCallback(
    (_path) => {
      if (!_path) {
        dispatch(unselectElement());
      } else {
        dispatch(selectElement({ path: _path }));
      }
    },
    [dispatch]
  );

  const generateList = (element) => {
    if (!element) return null;
    if (element.children.length === 0) return null;

    return element.children.map((el) => {
      const { titleTag, titleText } = getFormattedTitle(el);
      return {
        key: el.path,
        title: titleTag,
        titleText,
        children: generateList(el),
      };
    });
  };

  const dataList = generateList(source);

  const handleSearch = (value) => {
    let expandedKeys = [];
    let foundKeys = [];
    let autoExpand = false;
    if (value) {
      const findExpandedKeys = (data) => {
        for (const { key, titleText, children } of data) {
          if (titleText.indexOf(value) > -1) {
            const parentKey = getParentKey(key);
            foundKeys = uniq([...foundKeys, key]);
            if (parentKey) {
              expandedKeys = uniq([...expandedKeys, parentKey]);
            }
          }
          if (children?.length > 0) {
            findExpandedKeys(children);
          }
        }
      };
      findExpandedKeys(dataList);
      autoExpand = true;
    }
    setAutoExpandParent(autoExpand);
    dispatch(setExpandedPaths({ paths: expandedKeys }));
    setFoundPaths(foundKeys);
  };

  return (
    <div id="sourceContainer" className="tree-container">
      <Search
        style={{ marginBottom: 8 }}
        placeholder="Search"
        // enterButton
        onSearch={handleSearch}
      />

      {source && (
        <Tree
          onExpand={handleSetExpandedPaths}
          autoExpandParent={autoExpandParent}
          expandedKeys={expandedPaths}
          onSelect={(selectedPaths) => handleSelectElement(selectedPaths[0])}
          selectedKeys={[path]}
          treeData={dataList}
        />
      )}
      {!source && !sourceError && <i>{t('elementTreeDescription')}</i>}
      {sourceError && `Could not obtain source: ${JSON.stringify(sourceError)}`}
    </div>
  );
}
