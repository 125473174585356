/* eslint-disable camelcase */
export default class Framework {
  constructor(caps) {
    this.caps = caps || {};
    this.actions = [];
    this.variableStorage = {};
    this.localVarCount = 0;
    this.localVarCache = {};
    this.lastAssignedVar = null;
    this.isIOS = this.caps.platformName?.toLowerCase() === 'ios';
    this.isAndroid = this.caps.platformName?.toLowerCase() === 'android';
  }

  get name() {
    throw new Error('Must implement name getter');
  }

  get language() {
    throw new Error('Must implement language getter');
  }

  get extension() {
    throw new Error('Must implement extension getter');
  }

  addAction(action, params) {
    this.actions.push({ action, params });
  }

  wrapWithBoilerplate() {
    throw new Error('Must implement wrapWithBoilerplate');
  }

  indent(str, spaces) {
    const lines = str.split('\n');
    let spaceStr = '';
    for (let i = 0; i < spaces; i += 1) {
      spaceStr += ' ';
    }
    return lines
      .filter((l) => !!l.trim())
      .map((l) => `${spaceStr}${l}`)
      .join('\n');
  }

  getCodeString(includeBoilerplate = false) {
    let str = '';
    for (const { action, params } of this.actions) {
      const genCodeFn = `codeFor_${action}`;
      if (!this[genCodeFn]) {
        throw new Error(`Need to implement 'codeFor_${action}()'`);
      }
      const code = this[genCodeFn](...params);
      if (code) {
        str += `${code}\n`;
      }
    }
    if (includeBoilerplate) {
      return this.wrapWithBoilerplate(str);
    }
    return str;
  }

  getNewLocalVar() {
    this.localVarCount += 1;
    return `el${this.localVarCount}`;
  }

  getVarForFind(strategy, locator) {
    const key = `${strategy}-${locator}`;
    let wasNew = false;
    if (!this.localVarCache[key]) {
      this.localVarCache[key] = this.getNewLocalVar();
      wasNew = true;
    }
    this.lastAssignedVar = this.localVarCache[key];
    return [this.localVarCache[key], wasNew];
  }

  getVarName(varName, varIndex) {
    if (varIndex || varIndex === 0) {
      return `${varName}[${varIndex}]`;
    }
    return varName;
  }

  codeFor_findAndAssign() {
    throw new Error('Need to implement codeFor_findAndAssign');
  }

  codeFor_findElement(strategy, locator) {
    const [localVar, wasNew] = this.getVarForFind(strategy, locator);
    if (!wasNew) {
      // if we've already found this element, don't print out
      // finding it again
      return '';
    }

    return this.codeFor_findAndAssign(strategy, locator, localVar);
  }

  codeFor_tap() {
    throw new Error('Need to implement codeFor_tap');
  }

  codeFor_swipe() {
    throw new Error('Need to implement codeFor_tap');
  }

  codeFor_execute() {
    throw new Error('Need to implement codeFor_execute');
  }

  codeFor_scrollAndFindUntilVisible() {
    throw new Error('Need to implement codeFor_scrollAndFindUntilVisible');
  }

  codeFor_scrollWithSpecificCoordinates() {
    throw new Error('Need to implement codeFor_scrollWithSpecificCoordinates');
  }

  codeFor_longPress() {
    throw new Error('Need to implement codeFor_longPress');
  }

  codeFor_pressPhysicalButton() {
    throw new Error('Need to implement codeFor_pressPhysicalButton');
  }

  codeFor_switchApp() {
    throw new Error('Need to implement codeFor_switchApp');
  }
}
