import JsWdFramework from './js-mocha';
// import JavaJUnit4Framework from './java-junit4';
import JavaJUnit5Framework from './java-junit5';
// import PythonFramework from './python';
// import RubyFramework from './ruby';

const frameworks = {
  javascript_mocha: JsWdFramework,
  // java_junit4: JavaJUnit4Framework,
  java_junit5: JavaJUnit5Framework,
  // python_pytest: PythonFramework,
  // ruby_rspec: RubyFramework,
};

export default frameworks;
