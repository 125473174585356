import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useSelector } from 'react-redux';
import { currentTestSuiteSelector } from 'redux/TestSuites/selectors';
import { trimProps } from 'utils/validation';

export default function TargetAppForm({ form, isEditForm }) {
  const { t } = useTranslation();
  const { platformName } = useSelector(currentTestSuiteSelector);
  const isIOS = platformName === 'ios';
  const isAndroid = platformName === 'android';
  const { setFieldsValue } = form;

  return (
    <Form form={form} preserve={false}>
      <Form.Item
        name="appName"
        rules={[
          {
            required: true,
            message: t('requiredAppName'),
            whitespace: true,
          },
          {
            max: 30,
            message: t('validate.max', {
              key: t('appName'),
              value: 30,
            }),
          },
        ]}
        {...trimProps(setFieldsValue)}
      >
        <Input
          addonBefore={t('appName')}
          placeholder={t('appName')}
          disabled={isEditForm}
        />
      </Form.Item>
      {isIOS && (
        <Form.Item
          name="bundleId"
          rules={[
            {
              required: true,
              message: t('requiredBundleId'),
              whitespace: true,
            },
            {
              max: 155,
              message: t('validate.max', {
                key: 'Bundle Id',
                value: 155,
              }),
            },
          ]}
          {...trimProps(setFieldsValue)}
        >
          <Input addonBefore={t('bundleId')} placeholder="com.example.app" />
        </Form.Item>
      )}
      {isAndroid && (
        <>
          <Form.Item
            name="appPackage"
            rules={[
              {
                required: true,
                message: t('requiredAppPackage'),
                whitespace: true,
              },
              {
                max: 155,
                message: t('validate.max', {
                  key: t('appPackage'),
                  value: 155,
                }),
              },
            ]}
            {...trimProps(setFieldsValue)}
          >
            <Input
              addonBefore={t('appPackage')}
              placeholder="com.google.android.apps.maps"
            />
          </Form.Item>
          <Form.Item
            name="appActivity"
            rules={[
              {
                required: true,
                message: t('requiredAppActivity'),
                whitespace: true,
              },
              {
                max: 155,
                message: t('validate.max', {
                  key: t('appActivity'),
                  value: 155,
                }),
              },
            ]}
            {...trimProps(setFieldsValue)}
          >
            <Input
              addonBefore={t('appActivity')}
              placeholder="com.google.android.maps.MapsActivity"
            />
          </Form.Item>
          <Form.Item>
            <a
              href="/dashboard/tutorial#how-to-find-app-package-and-app-activity-of-your-android-app"
              target="_blank"
            >
              {t('howToGetAppActivity')}
            </a>
          </Form.Item>
        </>
      )}
    </Form>
  );
}

TargetAppForm.propTypes = {
  form: PropTypes.object,
  isEditForm: PropTypes.bool,
};
