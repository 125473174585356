import React from 'react';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { allowClose, quitSession } from 'redux/Inspector/slice';
import { currentTestCaseSelector } from 'redux/TestCases/selectors';
import { Button, Popconfirm, Space, Tooltip } from 'antd';
import { allowedCloseInspectorSelector } from 'redux/Inspector/selector';

import { CloseOutlined } from '@ant-design/icons';

export default function InspectorActions() {
  const dispatch = useDispatch();
  const { navigate } = useI18next();
  const { t } = useTranslation();
  const { id } = useSelector(currentTestCaseSelector);
  const allowedClose = useSelector(allowedCloseInspectorSelector);

  const handleCloseInspector = () => {
    dispatch(allowClose());

    navigate(`/dashboard/test-cases/${id}`);

    dispatch(quitSession());
  };

  return (
    <Space align="end" id="inspector-actions">
      <Popconfirm
        title={
          allowedClose ? t('confirmCloseInspector') : t('confirmSaveInspector')
        }
        onConfirm={handleCloseInspector}
      >
        <Tooltip title={t('closeInspector')} placement="left">
          <Button id="btnClose" type="primary" danger icon={<CloseOutlined />}>
            {t('close')}
          </Button>
        </Tooltip>
      </Popconfirm>
    </Space>
  );
}
