/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { parseCoordinates } from 'components/Inspector/shared';
import SelectedElement from './SelectedElement';
import './HighlighterRect.less';

/**
 * Absolute positioned divs that overlay the app screenshot and highlight the bounding
 * boxes of the elements in the app
 */
function HighlighterRect({
  selectedElement = {},
  onSelectHoveredElement,
  onUnselectHoveredElement,
  hoveredElement,
  onSelectElement,
  onUnselectElement,
  element,
  zIndex,
  scaleRatio,
  xOffset,
  elLocation,
  elSize,
}) {
  const { path: hoveredPath } = hoveredElement || {};
  const { path: selectedPath } = selectedElement || {};

  let width;
  let height;
  let left;
  let top;
  let key;
  const highlighterClasses = ['highlighter-box'];

  const handleSelectHoveredElement = useCallback(() => {
    onSelectHoveredElement(key);
  }, [key, onSelectHoveredElement]);

  const handleUnselectHoveredElement = useCallback(() => {
    onUnselectHoveredElement();
  }, [onUnselectHoveredElement]);

  const handleClickElement = useCallback(() => {
    if (key === selectedPath) {
      onUnselectElement();
    } else {
      onSelectElement(key);
    }
  }, [key, onSelectElement, onUnselectElement, selectedPath]);

  let highlighter = <div />;

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  const content = (
    <div onClick={handleStopPropagation}>
      <SelectedElement />
    </div>
  );

  const popover = (
    <Popover content={content} visible placement="topRight" arrowPointAtCenter>
      <div />
    </Popover>
  );

  if (element) {
    // Calculate left, top, width and height coordinates
    const { x1, y1, x2, y2 } = parseCoordinates(element);
    left = x1 / scaleRatio + xOffset;
    top = y1 / scaleRatio;
    width = (x2 - x1) / scaleRatio;
    height = (y2 - y1) / scaleRatio;

    // Add class + special classes to hovered and selected elements
    if (hoveredPath === element.path) {
      highlighterClasses.push('hovered-element-box');
    }
    if (selectedPath === element.path) {
      highlighterClasses.push('inspected-element-box');
      highlighter = popover;
    }
    key = element.path;
  } else if (elLocation && elSize) {
    width = elSize.width / scaleRatio;
    height = elSize.height / scaleRatio;
    top = elLocation.y / scaleRatio;
    left = elLocation.x / scaleRatio + xOffset;
    key = 'searchedForElement';
    highlighterClasses.push('inspected-element-box');
    highlighter = popover;
  }

  return (
    <div
      key={key}
      className={highlighterClasses.join(' ').trim()}
      onMouseOver={handleSelectHoveredElement}
      onFocus={handleSelectHoveredElement}
      onMouseOut={handleUnselectHoveredElement}
      onBlur={handleUnselectHoveredElement}
      onClick={handleClickElement}
      style={{
        zIndex,
        left: left || 0,
        top: top || 0,
        width: width || 0,
        height: height || 0,
      }}
    >
      {highlighter}
    </div>
  );
}

HighlighterRect.propTypes = {
  selectedElement: PropTypes.object,
  onSelectHoveredElement: PropTypes.func,
  onUnselectHoveredElement: PropTypes.func,
  onSelectElement: PropTypes.func,
  onUnselectElement: PropTypes.func,
  hoveredElement: PropTypes.object,
  element: PropTypes.object,
  zIndex: PropTypes.number,
  scaleRatio: PropTypes.number,
  xOffset: PropTypes.number,
  elLocation: PropTypes.object,
  elSize: PropTypes.number,
};

export default HighlighterRect;
