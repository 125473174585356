const hljs = require('highlight.js/lib/highlight');

hljs.registerLanguage('java', require('highlight.js/lib/languages/java'));
hljs.registerLanguage(
  'javascript',
  require('highlight.js/lib/languages/javascript')
);
hljs.registerLanguage('json', require('highlight.js/lib/languages/json'));
// hljs.registerLanguage('python', require('highlight.js/lib/languages/python'));
// hljs.registerLanguage('ruby', require('highlight.js/lib/languages/ruby'));

module.exports = hljs;
