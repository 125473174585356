import upperFirst from 'lodash/upperFirst';
import lowerCase from 'lodash/lowerCase';

/**
 * Convert variable name from camelCase and snake_case to readable letter
 * @param {String} text
 */
export function convertToReadable(text) {
  return upperFirst(lowerCase(text));
}

/**
 * Convert Object to String without quote in properties
 * https://stackoverflow.com/a/11233515/4642316
 * @param {Object} obj
 */
export function convertObjectToString(obj) {
  return JSON.stringify(obj).replace(/"([^(")"]+)":/g, '$1:');
}
