import React, { useCallback } from 'react';
import { Button, Typography } from 'antd';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useDispatch } from 'react-redux';
import ElementActions from 'containers/Inspectors/ElementActions';
import {
  showElementDetailDrawer,
  unselectElement,
} from 'redux/Inspector/slice';
import './SelectedElement.less';
import { CloseOutlined } from '@ant-design/icons';

const { Title } = Typography;

export default function SelectedElement() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleMoreDetail = useCallback(() => {
    dispatch(showElementDetailDrawer());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(unselectElement());
  }, [dispatch]);

  return (
    <div className="element-action container">
      <div className="element-detail">
        <Title level={5} style={{ display: 'inline' }}>
          {t('elementDetail')}
        </Title>
        <Button size="small" onClick={handleMoreDetail}>
          {t('moreDetail')}
        </Button>
      </div>
      <ElementActions />
      <div className="element-center">
        <Button
          type="primary"
          size="small"
          shape="round"
          danger
          icon={<CloseOutlined />}
          onClick={handleClose}
          className="custom-sm-btn"
        />
      </div>
    </div>
  );
}
