/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */

import Framework from './framework';
import { convertObjectToString } from '../../utils/text';

const suffixMap = {
  xpath: 'XPath',
  'accessibility id': 'AccessibilityId',
  id: 'Id',
  name: 'Name',
  'class name': 'ClassName',
  '-android uiautomator': 'AndroidUIAutomator',
  '-android datamatcher': 'AndroidDataMatcher',
  '-android viewtag': 'unsupported',
  '-ios predicate string': 'IosUIAutomation',
  '-ios class chain': 'IosClassChain',
};

class JsWdFramework extends Framework {
  get language() {
    return 'js';
  }

  get extension() {
    return '.js';
  }

  get frameworkName() {
    return 'javascript_mocha';
  }

  wrapWithBoilerplate(code) {
    const caps = JSON.stringify(this.caps, null, 6);

    const storage = Object.keys(this.variableStorage)
      .map(
        (k) =>
          `const ${k} = ${JSON.stringify(
            this.variableStorage[k].replace(/\$ENTER#/g, '\n')
          )};`
      )
      .join('\n');

    let utilImport = '';
    if (this.isIOS) {
      utilImport = 'pressIOSPhysicalButton';
    } else if (this.isAndroid) {
      utilImport = 'pressAndroidPhysicalButton';
    }

    return `const wd = require('wd');
const { expect } = require('chai');
const { scrollAndFindUntilVisible, scrollWithSpecificCoordinates, sleep, ${utilImport} } = require('./util');

// TODO: Initialize sharing text
${storage}

describe('TestSuite', async () => {
  let driver;

  // TODO: Before all
  before(async () => {
    driver = await wd.promiseChainRemote("http://localhost:4444/wd/hub");
  
    const caps = ${caps};
  
    await driver.init(caps);
  });
    
  // TODO: After all
  after(async () => {
    await driver.quit();
  });

  // TODO: Execute test cases
  it('Run test case', async () => {
${this.isAndroid
        ? this.indent(`// TODO: In the case of Android, need time to load
await sleep(3);`,
          4
        )
        : ''
      }
${this.indent(code, 4)}
  })
})`;
  }

  codeFor_findAndAssign = (strategy, locator, localVar, isArray) => {
    if (!suffixMap[strategy]) {
      throw new Error(`Strategy ${strategy} can't be code-gened`);
    }
    if (isArray) {
      return `const ${localVar} = await driver.elementsBy${suffixMap[strategy]}(${JSON.stringify(locator)});`;
    }
    return `const ${localVar} = await driver.elementBy${suffixMap[strategy]}(${JSON.stringify(locator)});`;
  };

  codeFor_click(varName, varIndex) {
    return `await ${this.getVarName(varName, varIndex)}.click();`;
  }

  codeFor_clear(varName, varIndex) {
    return `await ${this.getVarName(varName, varIndex)}.clear();`;
  }

  codeFor_sendKeys(varName, varIndex, value, variable) {
    return `await ${this.getVarName(varName, varIndex)}.sendKeys(${variable || JSON.stringify(value.replace(/\$ENTER#/g, '\n'))});`;
  }

  codeFor_text(varName, varIndex, expectedText, variable, strategy) {
    let code;
    switch (strategy) {
      case 'contain':
        code = `expect(await ${this.getVarName(varName, varIndex)}.text()).to.contain(${variable || JSON.stringify(expectedText)});`;
        break;
      case 'notContain':
        code = `expect(await ${this.getVarName(varName, varIndex)}.text()).not.to.contain(${variable || JSON.stringify(expectedText)});`;
        break;

      case 'notEqual':
        code = `expect(await ${this.getVarName(varName, varIndex)}.text()).not.equal(${variable || JSON.stringify(expectedText)});`;
        break;
      case 'equal':
      default:
        // equal
        code = `expect(await ${this.getVarName(
          varName,
          varIndex
        )}.text()).equal(${variable || JSON.stringify(expectedText)});`;
        break;
    }
    return code;
  }

  codeFor_back = () => 'await driver.back();';

  codeFor_tap = (varNameIgnore, varIndexIgnore, x, y) =>
    `await (new wd.TouchAction(driver)).tap({x: ${x}, y: ${y}}).perform();`;

  codeFor_swipe = (
    varNameIgnore,
    varIndexIgnore,
    x1,
    y1,
    x2,
    y2
  ) => `await (new wd.TouchAction(driver))
  .press({x: ${x1}, y: ${y1}})
  .moveTo({x: ${x2}, y: ${y2}})
  .wait({ ms: 3000 })
  .release()
  .perform();`;

  codeFor_sleep = (secs) => `await sleep(${secs});`;

  codeFor_refresh = () => '// TODO: refresh';

  codeFor_execute = (varNameIgnore, varIndexIgnore, command, args) => {
    const argsStr = convertObjectToString(args);
    return `await driver.execute(${JSON.stringify(command)}, ${argsStr});`;
  };

  codeFor_scrollAndFindUntilVisible = (varName, varIndex, search) => {
    const { value, variable, criteria, maxTries } = search;
    return `const ${this.getVarName(varName, varIndex)} = await scrollAndFindUntilVisible(driver, ${variable || JSON.stringify(value)}, ${JSON.stringify(criteria)}, ${maxTries});`;
  };

  codeFor_scrollWithSpecificCoordinates = (
    varNameIgnore,
    varIndexIgnore,
    swipe
  ) => {
    const { start, end, maxSwipe } = swipe;
    return `await scrollWithSpecificCoordinates(driver, ${convertObjectToString(
      start
    )}, ${convertObjectToString(end)}, ${convertObjectToString(maxSwipe)});`;
  };

  codeFor_longPress(varName, varIndex, seconds) {
    const milliseconds = seconds * 1000;

    return `await (new wd.TouchAction(driver))
  .press({ el: ${this.getVarName(varName, varIndex)} })
  .wait(${milliseconds})
  .release()
  .perform();`;
  }

  codeFor_pressPhysicalButton(_, __, buttonName, times = 1) {
    if (this.isIOS) {
      return `await pressIOSPhysicalButton(driver, ${JSON.stringify(buttonName)}, ${times});`
    }
    if (this.isAndroid) {
      return `await pressAndroidPhysicalButton(driver, ${JSON.stringify(buttonName)}, ${times});`
    }
    return `// Missing pressPhysicalButton function`
  }

  codeFor_switchApp(...args) {
    if (this.isIOS) {
      const [, , , bundleId] = args;
      return `await driver.execute("mobile: activateApp", { bundleId: ${JSON.stringify(bundleId)} });`;
    } if (this.isAndroid) {
      const [, , , appPackage, appActivity] = args;
      return `await driver.startActivity({ appPackage: ${JSON.stringify(appPackage)}, appActivity: ${JSON.stringify(appActivity)}});`
    }
  }
}

JsWdFramework.readableName = 'Javascript - Mocha';

export default JsWdFramework;
