/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
import Framework from './framework';

const suffixMap = {
  xpath: 'XPath',
  'accessibility id': 'AccessibilityId',
  id: 'Id',
  'class name': 'ClassName',
  name: 'Name',
  '-android uiautomator': 'AndroidUIAutomator',
  '-android datamatcher': 'AndroidDataMatcher',
  '-android viewtag': 'AndroidViewTag',
  '-ios predicate string': 'IosNsPredicate',
  '-ios class chain': 'IosClassChain',
};

class JavaFramework extends Framework {
  get language() {
    return 'java';
  }

  get extension() {
    return '.java';
  }

  get frameworkName() {
    return 'java_junit5';
  }

  wrapWithBoilerplate(code) {
    const [pkg, cls] = (() => {
      if (this.caps.platformName) {
        switch (this.caps.platformName.toLowerCase()) {
          case 'ios':
            return ['ios', 'IOSDriver'];
          case 'android':
            return ['android', 'AndroidDriver'];
          default:
            return ['unknownPlatform', 'UnknownDriver'];
        }
      } else {
        return ['unknownPlatform', 'UnknownDriver'];
      }
    })();

    const capStr = this.indent(
      Object.keys(this.caps)
        .map(
          (k) =>
            `desiredCapabilities.setCapability(${JSON.stringify(
              k
            )}, ${JSON.stringify(this.caps[k])});`
        )
        .join('\n'),
      8
    );

    const storage = this.indent(
      Object.keys(this.variableStorage)
        .map(
          (k) =>
            `private static String ${k} = ${JSON.stringify(
              this.variableStorage[k].replace(/\$ENTER#/g, '\n')
            )};`
        )
        .join('\n'),
      4
    );

    return `package biz.dockyard.moppium;

import io.appium.java_client.MobileElement;
import io.appium.java_client.${pkg}.${cls};
import io.appium.java_client.touch.WaitOptions;
import io.appium.java_client.touch.offset.PointOption;
import io.appium.java_client.touch.offset.ElementOption;
import io.appium.java_client.TouchAction;

import org.junit.jupiter.api.AfterAll;
import org.junit.jupiter.api.BeforeAll;
import org.junit.jupiter.api.DisplayName;
import org.junit.jupiter.api.Test;

import static org.junit.jupiter.api.Assertions.assertEquals;
import static org.junit.jupiter.api.Assertions.assertNotEquals;
import static org.hamcrest.MatcherAssert.assertThat;
import static org.hamcrest.CoreMatchers.not;
import static org.hamcrest.CoreMatchers.containsString;

import java.net.MalformedURLException;
import java.net.URL;
import java.util.LinkedHashMap;
import java.util.Map;
import java.time.Duration;
import java.io.File;
import java.io.IOException;

import org.apache.commons.io.FileUtils;

import org.openqa.selenium.remote.DesiredCapabilities;
import org.openqa.selenium.Dimension;
import org.openqa.selenium.OutputType;
import org.openqa.selenium.TakesScreenshot;
import org.openqa.selenium.Point;
import biz.dockyard.moppium.Utils;

@DisplayName("Run all test case in once")
public class TestCase {

    private static ${cls} driver;
    
    // TODO: Initialize sharing text
${storage}

    @BeforeAll
    public static void setUp() throws MalformedURLException {
        DesiredCapabilities desiredCapabilities = new DesiredCapabilities();
${capStr}

        URL remoteUrl = new URL("http://localhost:4444/wd/hub");

        driver = new ${cls}(remoteUrl, desiredCapabilities);

    }

    @Test
    @DisplayName("Run recorded actions")
    public void runTestCase() throws Exception {
${this.isAndroid
        ? this.indent(
          `// TODO: In the case of Android, need time to load
Thread.sleep(3000);`,
          8
        )
        : ''
      }
${this.indent(code, 8)}
  }

  @AfterAll
  public static void tearDown() {
    driver.quit();
  }
} `;
  }

  codeFor_findAndAssign(strategy, locator, localVar, isArray) {
    if (!suffixMap[strategy]) {
      throw new Error(`Strategy ${strategy} can't be code-gened`);
    }
    if (isArray) {
      return `List<MobileElement> ${localVar} = (MobileElement) driver.findElementsBy${suffixMap[strategy]}(${JSON.stringify(locator)});`;
    }
    return `MobileElement ${localVar} = (MobileElement) driver.findElementBy${suffixMap[strategy]}(${JSON.stringify(locator)});`;
  }

  getVarName(varName, varIndex) {
    if (varIndex || varIndex === 0) {
      return `${varName}.get(${varIndex})`;
    }
    return varName;
  }

  codeFor_click(varName, varIndex) {
    return `${this.getVarName(varName, varIndex)}.click();`;
  }

  codeFor_clear(varName, varIndex) {
    return `${this.getVarName(varName, varIndex)}.clear();`;
  }

  codeFor_sendKeys(varName, varIndex, value, variable) {
    return `${this.getVarName(varName, varIndex)}.sendKeys(${variable || JSON.stringify(value.replace(/\$ENTER#/g, '\n'))});`;
  }

  codeFor_text(varName, varIndex, expectedText, variable, strategy) {
    let code;
    switch (strategy) {
      case 'contain':
        code = `assertThat(${this.getVarName(varName, varIndex)}.getText(), containsString(${variable || JSON.stringify(expectedText)}));`; // TODO: Actual - Expected
        break;
      case 'notContain':
        // TODO: Actual - Expected
        code = `assertThat(${this.getVarName(varName, varIndex)}.getText(), not(containsString(${variable || JSON.stringify(expectedText)})));`;
        break;
      case 'notEqual':
        // TODO: Expected - Actual
        code = `assertNotEquals(${variable || JSON.stringify(expectedText)}, ${this.getVarName(varName, varIndex)}.getText());`;
        break;
      default:
        // equal
        code = `assertEquals(${variable || JSON.stringify(expectedText)}, ${this.getVarName(varName, varIndex)}.getText());`; // TODO: Expected - Actual
        break;
    }
    return code;
  }

  codeFor_back() {
    return 'driver.navigate().back();';
  }

  codeFor_tap(varNameIgnore, varIndexIgnore, x, y) {
    return `(new TouchAction(driver)).tap(PointOption.point(${x}, ${y})).perform();`;
  }

  codeFor_swipe(varNameIgnore, varIndexIgnore, startX, startY, endX, endY) {
    return `(new TouchAction(driver))
    .press(PointOption.point(${startX}, ${startY}))
    .waitAction(new WaitOptions().withDuration(Duration.ofSeconds(5)))
    .moveTo(PointOption.point(${endX}, ${endY}))
    .release()
    .perform();`;
  }

  codeFor_sleep(secs) {
    return `Thread.sleep(${secs * 1000});`;
  }

  codeFor_refresh() {
    return '// TODO: refresh';
  }

  codeFor_execute(varNameIgnore, varIndexIgnore, command, args) {
    return `driver.executeScript(${JSON.stringify(command)}, new LinkedHashMap() {{
        ${Object.entries(args).map(([key, value]) => `put(${JSON.stringify(key)}, ${JSON.stringify(value)});`)}
    }});`;
  }

  codeFor_scrollAndFindUntilVisible = (
    varName,
    varIndex,
    { variable, value, criteria, maxTries }
  ) =>
    `MobileElement ${this.getVarName(varName, varIndex)} = Utils.scrollAndFindUntilVisible(driver, ${variable || JSON.stringify(value)}, ${JSON.stringify(criteria)}, ${JSON.stringify(maxTries)});`;

  codeFor_scrollWithSpecificCoordinates = (
    varNameIgnore,
    varIndexIgnore,
    swipe
  ) =>
    `Utils.scrollWithSpecificCoordinates(driver, PointOption.point(${swipe.start.x}, ${swipe.start.y}), PointOption.point(${swipe.end.x}, ${swipe.end.y}), ${swipe.maxSwipe});`;

  codeFor_longPress(varName, varIndex, seconds) {
    return `new TouchAction(driver)
    .press(ElementOption.element(${this.getVarName(varName, varIndex)}))
    .waitAction(WaitOptions.waitOptions(Duration.ofSeconds(${seconds})))
    .release()
    .perform();`;
  }

  codeFor_pressPhysicalButton(_, __, buttonName, times = 1) {
    if (this.isIOS) {
      return `Utils.pressIOSPhysicalButton(driver, ${JSON.stringify(buttonName)}, ${times});`
    }
    if (this.isAndroid) {
      return `Utils.pressAndroidPhysicalButton(driver, ${JSON.stringify(buttonName)}, ${times});`
    }
    return `// Missing pressPhysicalButton function`
  }

  codeFor_switchApp(...args) {
    if (this.isIOS) {
      const [, , , bundleId] = args;

      return `driver.executeScript("mobile: activateApp", new LinkedHashMap() {{ put("bundleId", ${JSON.stringify(bundleId)}); }});`;
    } if (this.isAndroid) {
      const [, , , appPackage, appActivity] = args;

      return `driver.startActivity(${JSON.stringify(appPackage)}, ${JSON.stringify(appActivity)});`
    }
  }
}

JavaFramework.readableName = 'Java - JUnit 5';

export default JavaFramework;
