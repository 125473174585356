import React, { useState, useEffect, useCallback } from 'react';
import {
  CaretRightOutlined,
  CloudDownloadOutlined,
  CopyOutlined,
  DeleteOutlined,
  ExportOutlined,
  SaveOutlined,
  EyeOutlined,
  PauseOutlined,
} from '@ant-design/icons';
import {
  Card,
  Select,
  Button,
  notification,
  Popconfirm,
  Tooltip,
  Typography,
  Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import copy from 'utils/copy-text-to-clipboard';
import { highlight } from 'lib/highlight-less';
import frameworks from 'lib/client-frameworks';
import downloadSource from 'lib/download';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'react-fast-compare';
import {
  playbackActions,
  removeAllActions,
  save,
  startRecording,
  pauseRecording,
} from 'redux/Inspector/slice';
import {
  variableStorageSelector,
  capabilitiesSelector,
  allowedCloseInspectorSelector,
  recordingSelector,
  playingSelector,
  recordedActionsSelector,
  loadingScreenshotSelector,
} from 'redux/Inspector/selector';
import InspectorActions from 'containers/Inspectors/InspectorActions';
import Source from 'containers/Inspectors/Source';
import { useStoreActions, useZoomPanHelper } from 'react-flow-renderer';
import TabContainer from 'components/Inspector/TabContainer';
import ActionFlow from 'containers/ActionFlow/ActionFlow';
import {
  currentTestCaseLoadingSelector,
  currentTestCaseSelector,
} from 'redux/TestCases/selectors';

import './RecordedActions.less';

const { Option } = Select;
const ButtonGroup = Button.Group;
const { Text } = Typography;

function RecordedActions() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const recordedActions = useSelector(recordedActionsSelector);

  const { transform } = useZoomPanHelper();
  const setSelectedElements = useStoreActions(
    (actions) => actions.setSelectedElements
  );

  const [tab, setTab] = useState('tab1');
  const [boilerplate, setBoilerplate] = useState();
  const [frameworkName, setFrameworkName] = useState(
    Object.keys(frameworks)[0]
  );

  const allowedClose = useSelector(allowedCloseInspectorSelector);
  const variableStorage = useSelector(variableStorageSelector);
  const recording = useSelector(recordingSelector);
  const playing = useSelector(playingSelector);
  const capabilities = useSelector(capabilitiesSelector);
  const loading = useSelector(loadingScreenshotSelector);
  const { id, name } = useSelector(currentTestCaseSelector);
  const saving = useSelector(currentTestCaseLoadingSelector);

  const handleClearElements = useCallback(() => {
    dispatch(removeAllActions());
  }, [dispatch]);

  const handlePlaybackActions = () => {
    dispatch(playbackActions(transform, setSelectedElements));
  };

  const handleStartRecording = () => {
    dispatch(startRecording());
  };

  const handlePauseRecording = () => {
    dispatch(pauseRecording());
  };

  useEffect(() => {
    // If recorded actions is not saved will show warning
    if (!allowedClose) {
      window.onbeforeunload = () => true; // Show warning popup
    } else {
      window.onbeforeunload = undefined;
    }

    return () => {
      window.onbeforeunload = undefined;
    };
  }, [allowedClose]);

  const handleTabChange = useCallback((key) => {
    setTab(key);
  }, []);

  const code = (raw = true, includeBoilerplate = false) => {
    if (capabilities) {
      const framework = new frameworks[frameworkName](capabilities);
      // TODO: Add recorded action for framework (IMPORTANT)
      framework.actions = recordedActions;
      framework.variableStorage = variableStorage;
      const rawCode = framework.getCodeString(
        includeBoilerplate || boilerplate
      );
      if (raw) {
        return rawCode;
      }
      return highlight(framework.language, rawCode, true).value;
    }
    return highlight('java', '// COULD NOT GENERATE CODE', true).value;
  };

  const copyToClipboard = () => {
    /* Select the text field */
    copy(code(boilerplate));
    notification.success({
      message: t('clipboard'),
      description: t('copied'),
    });
  };

  const handleToggleBoilerplate = () => {
    setBoilerplate(!boilerplate);
  };

  const handleSaveRecordedActions = () => {
    dispatch(save(id));
  };

  const renderTabBarExtra = () => {
    const frameworkOpts = Object.keys(frameworks).map((f) => (
      <Option key={f} value={f}>
        {frameworks[f].readableName}
      </Option>
    ));

    const boilerplateType = boilerplate ? 'primary' : 'default';

    const allowTab2 = !!recordedActions.length && tab === 'tab2';
    const allowRecordAction = !!recordedActions.length || !recording;

    return (
      <Space>
        {allowTab2 && (
          <Select
            showSearch
            defaultValue={frameworkName}
            onChange={setFrameworkName}
            className="framework-dropdown"
            size="small"
          >
            {frameworkOpts}
          </Select>
        )}
        {tab === 'tab1' && (
          <Tooltip
            title={recording ? t('pauseRecord') : t('startRecord')}
            placement="bottom"
          >
            <Button
              size="small"
              icon={recording ? <PauseOutlined /> : <EyeOutlined />}
              onClick={recording ? handlePauseRecording : handleStartRecording}
              type="primary"
              ghost={!recording}
            >
              {recording ? t('recording') : t('record')}
            </Button>
          </Tooltip>
        )}
        {allowRecordAction && (
          <ButtonGroup size="small">
            {allowTab2 && (
              <Tooltip title={t('boilerplateTooltip')} placement="bottom">
                <Button
                  onClick={handleToggleBoilerplate}
                  icon={<ExportOutlined />}
                  type={boilerplateType}
                />
              </Tooltip>
            )}
            {allowTab2 && (
              <Tooltip title={t('downloadTooltip')} placement="bottom">
                <Button
                  onClick={() => {
                    downloadSource(code(true, true), frameworkName);
                  }}
                  icon={<CloudDownloadOutlined />}
                />
              </Tooltip>
            )}
            {allowTab2 && (
              <Tooltip title={t('copyTooltip')} placement="bottom">
                <Button icon={<CopyOutlined />} onClick={copyToClipboard} />
              </Tooltip>
            )}
            {!!recordedActions.length && tab !== 'tab3' && (
              <Popconfirm
                title={t('confirmClear')}
                onConfirm={handleClearElements}
              >
                <Tooltip title={t('clearAllActions')} placement="bottom">
                  <Button icon={<DeleteOutlined />} danger />
                </Tooltip>
              </Popconfirm>
            )}
          </ButtonGroup>
        )}
        <Tooltip title={t('saveActions')} placement="bottom">
          <Button
            size="small"
            loading={saving}
            id="btn-save"
            icon={<SaveOutlined />}
            onClick={handleSaveRecordedActions}
            type="primary"
            ghost
          >
            {t('save')}
          </Button>
        </Tooltip>
        {allowRecordAction && (
          <Tooltip title={t('playbackTooltip')} placement="bottom">
            <Button
              loading={playing}
              type="primary"
              onClick={handlePlaybackActions}
              icon={<CaretRightOutlined />}
              shape="circle"
              disabled={loading}
            />
          </Tooltip>
        )}
      </Space>
    );
  };

  const highlightedCode = code(false);

  const tabList = [
    {
      key: 'tab1',
      tab: t('actionFlow'),
    },
    {
      key: 'tab2',
      tab: t('sourceCode'),
    },
    {
      key: 'tab3',
      tab: t('elementTree'),
    },
  ];

  const title = (
    <>
      <Text>{t('testCase')}: </Text>
      <Text>{name}</Text>
    </>
  );

  return (
    <Card
      title={title}
      tabList={tabList}
      activeTabKey={tab}
      onTabChange={handleTabChange}
      extra={<InspectorActions />}
      tabBarExtraContent={renderTabBarExtra()}
      style={{
        height: '100%',
        overflow: 'hidden',
      }}
      bodyStyle={{ height: 'calc(100% - 97px)', overflow: 'auto' }}
    >
      <TabContainer tab="tab1" currentTab={tab}>
        <ActionFlow />
      </TabContainer>
      <TabContainer tab="tab2" currentTab={tab}>
        {!recordedActions.length && (
          <div className="no-recorded-actions">
            {t('sourceCodeDescription')}
          </div>
        )}
        {!!recordedActions.length && (
          <div
            className="recorded-code"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: highlightedCode }}
          />
        )}
      </TabContainer>
      <TabContainer tab="tab3" currentTab={tab}>
        <Source />
      </TabContainer>
    </Card>
  );
}

export default React.memo(RecordedActions, isEqual);
